import Link from "next/link"
import { memo } from "react"

import { ReactComponent as ArrowRightIcon } from "@spatialsys/assets/icons/lucide/arrow-right.svg"
import { TrackedComponent, TrackedComponents } from "@spatialsys/react/analytics"
import { MarketingVideo } from "@spatialsys/web/core/js/components/marketing-section/marketing-video/marketing-video"
import { Button, Container, Heading, Text } from "@spatialsys/web/ui"

export const WhatIsSpatial = memo(function WhatIsSpatial() {
  return (
    <TrackedComponent id={TrackedComponents.WhatIsSpatial} as="div">
      <Container className="p-8">
        <div className="relative grid items-stretch gap-4 overflow-hidden rounded-xl border border-white/60 bg-radial-blur p-4 shadow-md xs:grid-cols-2 xs:p-5 sm:gap-8 sm:p-7 md:gap-12 md:rounded-3xl md:p-10 lg:p-12 xl:gap-24 2xl:grid-cols-[4fr_5fr]">
          <div className="z-10">
            <MarketingVideo
              posterPath="hero-carousel/authless_video_header.jpg"
              videoPath="hero-carousel/authless_video_header.mp4"
              webmVideoPath="hero-carousel/authless_video_header.webm"
              className="h-full min-h-[150px] w-full rounded object-cover object-center"
            />
          </div>
          <div className="z-10 xs:row-start-1">
            <Heading weight="black" size="h3" className="mb-4 lg:text-m3">
              What is Spatial?
            </Heading>
            <Text size="sm" className="lg:text-base xl:text-lg">
              Spatial is a free online gaming platform where millions of creators build and share fun and inspiring
              games that bring people together across web, mobile, and VR.
            </Text>
            <br />
            <Text size="sm" className="lg:text-base xl:text-lg">
              As videos once transformed content sharing, we're evolving the gaming realm, pushing boundaries beyond
              passive viewing to immersive interactions. Amplify your creativity with our Unity-powered toolkit, thrive
              in a creator-friendly virtual economy, and publish across all platforms. Dive into a universe where play
              meets limitless potential.
            </Text>
            <Button
              color="white"
              variant="text"
              as={Link}
              href="/about"
              className="mt-3 p-0 font-bold md:text-base lg:text-lg"
              rightIcon={<ArrowRightIcon className="icon icon-xs" />}
            >
              About Us
            </Button>
          </div>
        </div>
      </Container>
    </TrackedComponent>
  )
})
